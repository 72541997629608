import data from "./data.json";
import "./style.scss";
export default function PopularPage() {
  return (
    <div className="container popular_container">
      <div className="content_container">
        <h2 className="title">{data.title}</h2>
        <p className="introduction">{data.introduction}</p>
        {data.sections.map((item) => (
          <div className="text_container">
            <h3 className="heading">{item.heading}</h3>
            <img src={item.image} alt={item.heading} className="image" />
            <p className="content">{item.content}</p>
          </div>
        ))}
        <p className="conclusion">{data.conclusion}</p>
      </div>
    </div>
  );
}
