import "./head.scss";
import SvgIcon from "../svg/svgImport";
import { NavLink } from "react-router-dom";

export default function Head({ onClick }: any) {
  const handleBtnClick = () => {
    onClick();
  };

  return (
    <div className="head_container container">
      <div className="logo_container">
        <NavLink to="/">
          <img src="/logo.png" alt="logo" className="logo" />
        </NavLink>
      </div>
      <button className="burger_btn" onClick={handleBtnClick}>
        <SvgIcon.menu className="burger_icon" />
      </button>
    </div>
  );
}
