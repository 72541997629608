import baner from "./baner_img.png";
import "./baner.scss";
import chip1 from "./animation/chip.png";
import chip2 from "./animation/chip_.png";
import cards from "./animation/cards.png";
import Button from "../button/button";
import { useNavigate } from "react-router-dom";

export default function Baner() {
  let navigate = useNavigate();

  const handleBtnClick = () => {
    navigate("/popular");
  };
  return (
    <div
      className="baner_container"
      style={{ backgroundImage: `url(${baner})` }}
    >
      <div className="baner">
        <h2 className="title">Poker : Your Game, Your Rules </h2>
        <Button params={"Win Today!"} onClick={handleBtnClick} />
      </div>
      <img src={chip1} alt="chip" className="animation_item first_chip" />
      <img src={chip2} alt="chip" className="animation_item second_chip" />
      <img
        src={cards}
        alt="cards"
        className="animation_item cards_animations"
      />
    </div>
  );
}
