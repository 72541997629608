import { useParams } from "react-router-dom";
import data from "../../data/articles.json";
import { useState } from "react";
import "./styles.scss";

const findItem = (id: any) => {
  const findData = data.find((item) => item.id === id);
  if (findData) {
    return findData;
  } else {
    return data[0];
  }
};
export default function SelectedArticlePage() {
  let { id } = useParams();
  const [data, setData] = useState(findItem(id));

  return (
    <div className="container selected_arlicles_container">
      <img src={data.img} alt={data.title} className="image" />
      <div className="content_text_container">
        {data.content.map((item, index) => (
          <p key={index} className="text">
            {item.text}
          </p>
        ))}
      </div>
    </div>
  );
}
