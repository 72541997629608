import "./modalStyle.scss";
import SvgIcon from "../svg/svgImport";
import { NavLink } from "react-router-dom";
export default function Modal({ onClick }: any) {
  const handleBtnClick = () => {
    onClick();
  };
  return (
    <div className="modal_container">
      <button type="button" className="button" onClick={handleBtnClick}>
        <SvgIcon.close className="close_ico" />
      </button>
      <nav className="nav_container">
        <NavLink
          to="/"
          className={({ isActive }) => (isActive ? "active" : "link")}
          onClick={handleBtnClick}
        >
          Home
        </NavLink>
        <NavLink
          to="/articles"
          className={({ isActive }) => (isActive ? "active" : "link")}
          onClick={handleBtnClick}
        >
          Articles
        </NavLink>

        <NavLink
          to="/privacy"
          className={({ isActive }) => (isActive ? "active" : "link")}
          onClick={handleBtnClick}
        >
          Policy
        </NavLink>
      </nav>
    </div>
  );
}
