export default function Button({ params, onClick, isSelected }: any) {
  return (
    <button
      onClick={(e) => onClick(e)}
      name={params}
      //   className="interface_btn"
      className={`interface_btn ${isSelected ? "active" : ""}`}
      key={params}
    >
      {params}
    </button>
  );
}
