import "./articlesPageStyle.scss";
import ListItem from "../../list/listItem";
import data from "../../data/articles.json";
import Button from "../../button/button";
import { useState } from "react";
import { deepCopy } from "../../../utils/deep-copy";
const category = ["all", "slots", "aviator", "blackjack", "betting", "poker"];
export default function ArticlesPage() {
  const [selectedCategories, setSelected] = useState(["all"]);
  const handleCategorySelect = (e: any) => {
    const category = e.target.name;
    if (category === "all") {
      setSelected(["all"]);
    } else {
      if (selectedCategories.includes("all")) {
        setSelected([category]);
      } else {
        const newCategories = deepCopy(selectedCategories);

        if (newCategories.includes(category)) {
          newCategories.splice(newCategories.indexOf(category), 1);
        } else {
          newCategories.push(category);
        }
        if (newCategories.length === 0) {
          newCategories.push("all");
        }

        setSelected(newCategories);
      }
    }
  };

  const filterContentByCategories = () => {
    if (selectedCategories.includes("all")) {
      return data;
    } else {
      return data.filter((item) => selectedCategories.includes(item.category));
    }
  };
  return (
    <div className="article_page_container container">
      <div className="button_container">
        {category.map((item, index) => (
          <Button
            params={item}
            isSelected={selectedCategories.includes(item)}
            onClick={handleCategorySelect}
          />
        ))}
      </div>
      <ul className="list">
        {/* {data.map((item) => (
          <ListItem params={item} />
        ))} */}
        {filterContentByCategories().map((item) => (
          <ListItem params={item} />
        ))}
      </ul>
    </div>
  );
}
