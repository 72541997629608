import "./homePageStyle.scss";
import data from "../../data/articles.json";
import ListItem from "../../list/listItem";
import Baner from "../../baner/baner";
import Animation from "../../animationTlo/animation";
import { useEffect, useState } from "react";
export default function HomePage() {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const setAnimateWindow = () => {
      setShow(!show);
    };
    setTimeout(setAnimateWindow, 2500);
  }, []);
  return (
    <>
      {show && <Animation />}
      <Baner />
      <div className=" home_page_container container">
        <ul className="home_list">
          {data.map((item) => (
            <ListItem params={item} key={item.id} />
          ))}
        </ul>
      </div>
    </>
  );
}
