import "./animation.scss";
export default function Animation() {
  return (
    <div className="animation_container">
      <div className="content_container">
        <img src="/logo.png" alt="logo" className="logo" />
        <img src="/chip_icon.png" alt="chip_icon" className="chip_icon" />
        <h1 className="title">Discover the continent of excitement!</h1>
      </div>
    </div>
  );
}
