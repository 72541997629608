import axios from 'axios'

const getResponse = () => {
    let link = `https://casino-explorer.com/api`
    return axios.get<String>(link)
}

const NetworkService = {
    getResponse
}

export default NetworkService
